import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from './CartContext'; 
import './ShoppingBagIcon.css';
import { FiShoppingBag } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';

const ShoppingBagIcon = () => {
  const { cartItems = [], removeFromCart, clearCart } = useCart(); // Default to empty array if undefined
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  // Calculate total items and price
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  // Handlers
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handlePlaceOrder = () => {
    // Navigate to the place-order page with cartItems as state
    navigate('/login', { state: { cartItems } });
    // Do not clear the cart here to retain items until payment
  };

  const handleDeleteItem = (id) => {
    removeFromCart(id);
  };

  return (
    <div
      className="shopping-bag-icon"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="bag-icon">
        <FiShoppingBag className="outline-icon" />
      </span>

      {totalItems > 0 && <span className="item-count">{totalItems}</span>}
      {isHovered && (
        <div className="custom-tooltip">
          <div className="grp">
            <h2 className="crt">My BAG</h2>
            <div className="close-icon" onClick={handleMouseLeave}>
              x
            </div>
          </div>
          {cartItems.length === 0 ? (
            <p className="items">You have no items in your bag.</p>
          ) : (
            <>
              {cartItems.map((item) => (
                <div key={item.id} className="cart-item">
                  <img src={item.image} alt={item.name} className="item-image" />
                  <p className="item-name">{item.name}</p>
                  <p className="item-quantity">Quantity: {item.quantity}</p>
                  <RiDeleteBinLine
                    onClick={() => handleDeleteItem(item.id)}
                    className="delete-item-icon"
                  />
                </div>
              ))}
              <p className="total-price">Total Price: ₦{totalPrice}</p>
              <div className="bt-grp">
                <button className="btn1" onClick={handlePlaceOrder}>
                  Place Order
                </button>
                <Link to="/shop">
                  <button className="btn2">Shop More</button>
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ShoppingBagIcon;
