import React from 'react';
import './Contact.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebookF, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';

const Contact = () => {
  return (
    <>
      <div className="contact-container">
        <h1>Contact Us</h1>
        <p className="subtitle">Our agents are trained to provide exceptional support. Our team is committed to delivering top-notch assistance, ensuring your satisfaction with every interaction.</p>
        <div className="contact-content">
          <div className="contact-info">
            <h2 className='get-in-text'>Get In Touch</h2>
            <p className='get-subtext'>Have questions about our handcrafted shoes or leather goods? Need assistance with sizing, care, or custom orders? We’re here to help! Reach out to us with any inquiries you may have.</p>
            <div className="info-item">
              <FaMapMarkerAlt className="icon" />
              <p>32 Ladipo Street, Mushin, Lagos, Nigeria</p>
            </div>
            <div className="info-item">
              <FaPhone className="icon" />
              <p>(+234) 818 879 6193</p>
            </div>
            <div className="info-item">
              <FaEnvelope className="icon" />
              <p>orders@minuel.com</p>
            </div>
            <div className="social-icons">
              <p className='follow-us'>Follow us:</p>
              <FaFacebookF className="icon" />
              <FaTwitter className="icon" />
              <FaYoutube className="icon" />
              <FaInstagram className="icon" />
            </div>
          </div>
          <div className="contact-form">
            <h2 className='send-msg'>Send a Message</h2>
            <form>
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="E-mail address" />
              <textarea placeholder="Message"></textarea>
              <p className="privacy-notice">By submitting, you agree to the processing of your personal data by Minuel Shoes as described in the Privacy Statement.</p>
              <button type="submit" className='submit-btn'>Submit</button>
            </form>
          </div>
        </div>
        <div className="map-container">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.541345025622!2d3.354974014305224!3d6.531217625355206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9364d9eead5f%3A0x7834954a3fdab67e!2s32%20Ladipo%20St%2C%20Mushin%2C%20Lagos!5e0!3m2!1sen!2sng!4v1625565192953!5m2!1sen!2sng"
            width="600"
            height="350"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <Footer />
      <Bottomft />
    </>
  );
};

export default Contact;
