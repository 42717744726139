import React, { useEffect, useState } from 'react';
import './Ladies.css';

const Ladies = () => {
  useEffect(() => {
    const consoleText = (words, id, colors) => {
      if (colors === undefined) colors = ['#fff'];
      let visible = true;
      let letterCount = 1;
      let x = 1;
      let waiting = false;
      const target = document.getElementById(id);

      target.style.color = colors[0];

      const interval1 = setInterval(() => {
        if (letterCount === 0 && waiting === false) {
          waiting = true;
          target.innerHTML = words[0].substring(0, letterCount);
          setTimeout(() => {
            const usedColor = colors.shift();
            colors.push(usedColor);
            const usedWord = words.shift();
            words.push(usedWord);
            x = 1;
            target.style.color = colors[0];
            letterCount += x;
            waiting = false;
          }, 1000);
        } else if (letterCount === words[0].length + 1 && waiting === false) {
          waiting = true;
          setTimeout(() => {
            x = -1;
            letterCount += x;
            waiting = false;
          }, 1000);
        } else if (waiting === false) {
          target.innerHTML = words[0].substring(0, letterCount);
          letterCount += x;
        }
      }, 120);

      const interval2 = setInterval(() => {
        if (visible === true) {
          target.className = 'console-underscore hidden';
          visible = false;
        } else {
          target.className = 'console-underscore';
          visible = true;
        }
      }, 400);

      return () => {
        clearInterval(interval1);
        clearInterval(interval2);
      };
    };

    const words = [
      'Discover the essence of sophistication',
      '',
      'and indulge in the artistry of Minuel',
      '',
      'where luxury meets handmade perfection.'
    ];
    consoleText(words, 'text', ['white']);
  }, []);

  return (
    <section className="ladies-section">
      <div className="ladies-content">
        <div className="ladies-text">
          <h2 className='glam'>GLAMOROUS ELEGANCE</h2>
          <p className="mirror">
            Our mirrored sandal embodies Minuel's experimental attitude, continuously combining
            artisanal tradition with glamorous elegance.
          </p>
          <button>SHOP NOW</button>
        </div>


        <div id="text" className="other-div"></div>

      </div>

    </section>
  );
};

export default Ladies;
