// Modal.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Modal.css';
import logo from './assets/logo.png';

const Modal = ({ isOpen, onClose }) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <div>
          <div className="logo">
            <img src={logo} alt="Logo" style={{ width: '60px' }} />
          </div>
        </div>
        <div>
          <ul className='modalul'>
            <li className='modalli'>
              {/* Use Link to navigate to the login page */}
              <Link to="/login" onClick={onClose}>
                Sign in
              </Link>
            </li>
            <li className='modalli'>My Account</li>
            <li className='modalli'>My Orders</li>
            <li className='modalli'>My Wishlist</li>
            <li className='modalli'>Subscribe to Newsletter</li>
            <li className='modalli'>Ship from: USA</li>
            <li className='modalli'>Returns</li>
            <li className='modalli'>Gift Cards</li>
            <li className='modalli'>Contact</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Modal;
