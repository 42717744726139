import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PaystackIntegration from './Paystackintegration'; // Adjust path as needed
import DHLLogo from './assets/DHL.png';
import FedExLogo from './assets/FedEx.png';
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';

import './PlaceOrder.css';

const PlaceOrder = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems = [] } = location.state || {}; // Default to empty array if undefined

  const [shippingInfo, setShippingInfo] = useState({
    fullName: '',
    streetAddress: '',
    city: 'Lagos',
    state: '',
    countryRegion: 'Nigeria',
    phone: '',
    email: '',
    shippingMethod: 'standard',
  });

  const handleShippingInfoChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleShippingMethodChange = (e) => {
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      shippingMethod: e.target.value,
    }));
  };

  const calculateTotalAmount = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const getShippingCost = () => {
    return shippingInfo.shippingMethod === 'standard' ? 5000 : 10000;
  };

  const getShippingDescription = () => {
    switch (shippingInfo.shippingMethod) {
      case 'standard':
        return '₦5,000.00 - Standard (1-5 business days in continental NG)';
      case 'fedex':
        return 'FedEx Int.';
      case 'dhl':
        return 'DHL Express';
      default:
        return 'No shipping method selected';
    }
  };

  const totalAmount = calculateTotalAmount() + getShippingCost();

  const handlePaymentSuccess = (response) => {
    console.log('Payment successful:', response.reference);
    const orderDetails = {
      reference: response.reference,
      amount: totalAmount,
      email: shippingInfo.email,
      shippingInfo,
      cartItems,
    };
    navigate('/order-confirm', { state: { ...orderDetails, totalAmount } });
  };

  const handlePaymentCancel = () => {
    console.log('Payment closed without completion');
  };

  return (
    <>
      <div className="container">
        <div className="billing-details">
          <h2 className="ships">Shipping Address</h2>
          <form>
            <div className="form-group">
              <label htmlFor="fullName">Full Name *</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={shippingInfo.fullName}
                onChange={handleShippingInfoChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="streetAddress">Street Address *</label>
              <input
                type="text"
                id="streetAddress"
                name="streetAddress"
                value={shippingInfo.streetAddress}
                onChange={handleShippingInfoChange}
                required
              />
            </div>
            <div className="city-state">
              <div className="form-group">
                <label htmlFor="city">City *</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={shippingInfo.city}
                  onChange={handleShippingInfoChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State *</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={shippingInfo.state}
                  onChange={handleShippingInfoChange}
                  required
                />
              </div>
            </div>
            <div className="phone-email">
              <div className="form-group">
                <label htmlFor="phone">Phone *</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={shippingInfo.phone}
                  onChange={handleShippingInfoChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="emails"
                  name="email"
                  value={shippingInfo.email}
                  onChange={handleShippingInfoChange}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="countryRegion">Country/Region *</label>
              <input
                type="text"
                id="countryRegion"
                name="countryRegion"
                value={shippingInfo.countryRegion}
                onChange={handleShippingInfoChange}
                required
              />
            </div>
          </form>
          <div className="shipping-methods">
            <h4>Shipping Methods</h4>
            <div className="shipping-option">
              <input
                type="radio"
                id="standardShipping"
                name="shippingMethod"
                value="standard"
                checked={shippingInfo.shippingMethod === 'standard'}
                onChange={handleShippingMethodChange}
              />
              <label htmlFor="standardShipping">
                <div className="radio-circle"></div>
                <p>₦5,000.00 - Standard (1-5 business days in continental NG)</p>
              </label>
            </div>
            <div className="shipping-option">
              <input
                type="radio"
                id="fedexShipping"
                name="shippingMethod"
                value="fedex"
                checked={shippingInfo.shippingMethod === 'fedex'}
                onChange={handleShippingMethodChange}
              />
              <label htmlFor="fedexShipping">
                <div className="radio-circle"></div>
                <p>FedEx Int.</p>
                <img src={FedExLogo} alt="FedEx" className="shipping-logo" />
              </label>
            </div>
            <div className="shipping-option">
              <input
                type="radio"
                id="dhlShipping"
                name="shippingMethod"
                value="dhl"
                checked={shippingInfo.shippingMethod === 'dhl'}
                onChange={handleShippingMethodChange}
              />
              <label htmlFor="dhlShipping">
                <div className="radio-circle"></div>
                <p>DHL Express</p>
                <img src={DHLLogo} alt="DHL" className="shipping-logo" />
              </label>
            </div>
          </div>
        </div>

        <div className="order-summary">
          <h3 className="orderSum">Order Summary</h3>
          <ul>
            {cartItems.map((item) => (
              <li key={item.id}>
                <div className="item-summary">
                  <img src={item.image} alt={item.name} className="item-image" />
                  <div className="item-details">
                    <p className="item-name">{item.name}</p>
                    <p className="item-quantity">Quantity: {item.quantity}</p>
                    <p className="item-price">₦{(item.price * item.quantity).toLocaleString()}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <p className="shipping-description">
            Shipping Method: {getShippingDescription()}
          </p>
          <p className="total-amount">
            Total Amount: ₦{totalAmount.toLocaleString()}
          </p>

          <PaystackIntegration
            amount={totalAmount}
            email={shippingInfo.email} // Ensure this is not empty
            fullName={shippingInfo.fullName} // Ensure this is not empty
            onSuccess={handlePaymentSuccess}
            onCancel={handlePaymentCancel}
        
          />
        </div>
      </div>

      <Footer />
      <Bottomft />
    </>
  );
};

export default PlaceOrder;
