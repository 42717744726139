import React from 'react';
import { Link } from 'react-router-dom';

import thumbnailImage1 from './assets/care1.jpg';
import thumbnailImage2 from './assets/care2.webp';
import thumbnailImage3 from './assets/care3.webp';

import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';

import './Shoecare.css';


const ShoeCarePosts = () => {
  return (
    <>
    <div className="every">
      <h3 className="shoe-text">Shoe Care</h3>
      <p className="sub">Professional's secret! Get updated tips on how to revive your favorite leather shoes and bring out their best look. </p>
      <div className="thumbnail-posts">
        <div className="thumbnail-post">
          <img src={thumbnailImage1} alt="Thumbnail 1" />
          <h1>Daily Leather Care Tips</h1>
          <p>Learn how to properly care for your leather shoes to keep them looking their best.</p>
          <Link to="/leather-care" className="more">Read More</Link>
        </div>
        <div className="thumbnail-post">
          <img src={thumbnailImage2} alt="Thumbnail 2" />
          <h1>Sneaker Cleaning Guide</h1>
          <p>Discover the best practices for cleaning and maintaining your favorite sneakers.</p>
          <Link to="/sneaker-cleaning" className="more">Read More</Link>
        </div>
        <div className="thumbnail-post">
          <img src={thumbnailImage3} alt="Thumbnail 3" />
          <h1>Remove Shoe Stains</h1>
          <p>Find out effective methods to remove common stains from different types of shoes.</p>
          <Link to="/stain-removal" className="more">Read More</Link>
        </div>
      </div>
    </div>

    <Footer />
     <Bottomft />
    </>
  );
};

export default ShoeCarePosts;

