import React from "react";
import BackToTopButton from "./BackToTopButton";
import "./Bottomft.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p className="btft"> &copy; Minuel Handmade Luxury {new Date().getFullYear()} All Rights Reserved | Developed by Wells Ideas</p>
      <BackToTopButton />
      <div className="caret-up-icon">
        <i className="fas fa-caret-up"></i>
      </div>
    </footer>
  );
};

export default Footer;
