// Newsletter.js

import React from 'react';
import './Newsletter.css'; // Import the CSS file for styling

const Newsletter = () => {
  return (
    <div className="newsletter-container">
      <h3>Subscribe to our Newsletter</h3>
      <form className="newsletter-form">
        <input type="email" placeholder="Enter your email" />
        <button type="submit">Subscribe</button>
      </form>
    </div>
  );
};

export default Newsletter;
