import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Assuming you have your CSS styles defined in Login.css
import firebase from './firebaseConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      if (user) {
        const { displayName } = user; // Assuming the user has a display name
        alert('Login successful');
        navigate('/account', {
          replace: true,
          state: { user: { name: displayName || 'User' } }, // Using display name or 'User' if not available
        });
        // Redirect to the 'account' page upon successful login
      }
    } catch (error) {
      alert(error.message);
      // Handle login errors here, update state or display error messages
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-heading">Welcome Back! Please Sign In</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <label htmlFor="email" className="login-label">Email</label>
        <input type="email" id="login-email" className="login-input" value={email} onChange={(e) => setEmail(e.target.value)} required />

        <label htmlFor="password" className="login-label">Password</label>
        <input type="password" id="password" className="login-input" value={password} onChange={(e) => setPassword(e.target.value)} required />

        <button className='submit-button' type="submit">SIGN IN</button>
      </form>
      <p className="login-signup-text">
        Don't have an account? <a href="/signup" className="signup-link">Create one</a>
      </p>
      <a href="/forgot-password" className="forgot-password-link">Forgot password?</a>
    </div>
  );
};

export default Login;
