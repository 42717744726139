import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
import 'firebase/compat/database'

const firebaseConfig = {
  apiKey: "AIzaSyAqxRKY9e9mB3wmKG1L6zSVhYo0qjgAd7c",
  authDomain: "minuel-store.firebaseapp.com",
  projectId: "minuel-store",
  storageBucket: "minuel-store.appspot.com",
  messagingSenderId: "584305998594",
  appId: "1:584305998594:web:2c304acb804dc70c97201b"
};



const app = firebase.initializeApp(firebaseConfig);
export default app