import React from 'react';
import './Promo.css';
import slippersImage from './assets/belt.jpeg';

const Promo = () => {
  return (
    <section className="promo-section">
      <div className="promo-content">
        <div className="promo-text">
          <h2><span className="bold">X1</span> <span className="bold">Belts</span> are selling fast this season</h2>
          <button className="promo-btn-order"> <a href="#">ORDER NOW</a>
</button>
        </div>
        <div className="promo-image">
          <img src={slippersImage} alt="Slippers" />
        </div>
      </div>
    </section>
  );
};

export default Promo;
