import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Topbar from './components/Topbar';
import { CartProvider } from './components/CartContext';
import Home from './components/Home';
import Men from './components/Men';
import Women from './components/Women';
import Request from './components/Request';
import Sneakers from './components/Sneakers';
import Shoecare from './components/Shoecare';
import Contact from './components/Contact';
import Checkout from './components/Checkout';
import Signin from './components/Signin';
import Newsletter from './components/Newsletter';
import Login from './components/Login';
import Signup from './components/Signup';
import PlaceOrder from './components/PlaceOrder';
import Paystackintegration from './components/Paystackintegration'; 
import Account from './components/Account';
import OrderConfirm from './components/OrderConfirm';
import ShoppingBagIcon from './components/ShoppingBagIcon';
import Cart from './components/Cart';

const App = () => {
  return (
    <Router>
      <CartProvider>
        <Topbar />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/men" element={<Men />} />
          <Route path="/women" element={<Women />} />
          <Route path="/request" element={<Request />} />
          <Route path="/sneakers" element={<Sneakers />} />
          <Route path="/shoecare" element={<Shoecare />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/place-order" element={<PlaceOrder />} />
          <Route path="/paystackintegration" element={<Paystackintegration />} />
          <Route path="/account" element={<Account />} />
          <Route path="/order-confirm" element={<OrderConfirm />} />
          <Route path="/shopping-bag" element={<ShoppingBagIcon />} />
          <Route path="/cart" element={<Cart />} />
        </Routes>
      </CartProvider>
    </Router>
  );
};

export default App;
