import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer full-width">
      <div className="column">
        <h3>About Us</h3>
        <p className='abt'>
          Minuel is a fashion lifestyle accessory company with a focus on shoes. We are passionate about crafting high-quality footwear that combines style and comfort. Our mission is to provide fashionable and sustainable footwear options for our customers.<br></br>
          We take pride in our commitment to environmental responsibility. At Minuel, we manufacture our shoes using environmentally friendly practices and materials. We strive to minimize our carbon footprint and promote sustainability throughout our production process.
        </p>
      </div>

      <div className="column">
        <h3>Contact Us</h3>
        <ul>
          <li>Phone: (+234) 818 879 6193</li>
          <li>Email: orders@minuel.com</li>
        </ul>

        <h3>Newsletter</h3>
        <form className="newsletter-form">
          <input type="text" name="name" placeholder="Name" />
          <input type="email" name="email" placeholder="Email" />
          <button className="sub" type="submit">Subscribe</button>
        </form>
      </div>

      <div className="column">
        <h3>Useful Links</h3>
        <ul>
          <li>Shoe Style Guide</li>
          <li>Shoe Care Guide</li>
          <li>Last Shape Guide</li>
          <li>Help/FAQ</li>
          <li>Price promise</li>
          <li>Tax Calculator</li>
          <li>Shipping Information</li>
          <li>Returns Policy</li>
          <li>Terms & Conditions</li>
          <li>Privacy policy</li>
          <li>Sustainability</li>
        </ul>
      </div>

      <div className="column">
        <h3>Categories</h3>
        <ul>
          <li>Men's Suede</li>
          <li>Suede Boots</li>
          <li>Black Double</li>
          <li>Mens Suede Boots</li>
          <li>Men's Deck Shoes</li>
          <li>Minuel Shoe Care</li>
          <li>Minuel Luggage</li>
          <li>Minuel Belts</li>
        </ul>
      </div>

      <div className="column">
        <h3>Social</h3>
        <ul className="social-links">
          <li>
          <FontAwesomeIcon icon={faFacebook} /> 
            <a href="#">
           Facebook
            </a>
          </li>
          <li>
          <FontAwesomeIcon icon={faTwitter} /> 
            <a href="#">
             Twitter
            </a>
          </li>
          <li>
          <FontAwesomeIcon icon={faYoutube} /> 
            <a href="#">
            Youtube
            </a>
          </li>
          <li>
          <FontAwesomeIcon icon={faInstagram} /> 
            <a href="#">
            Instagram
            </a>
          </li>
          <li>
          <FontAwesomeIcon icon={faLinkedin} /> 
            <a href="#">
        LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
