import React, { useState } from 'react';
import './Women.css';
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';
import sneaker1 from './assets/products/lady10-01.jpg';
import sneaker2 from './assets/products/product1.png';
import sneaker3 from './assets/products/sneaker.jpeg';
import sneaker4 from './assets/products/products5.png';
import sneaker5 from './assets/products/brown.png';
import sneaker6 from './assets/products/nubuck.png';
import boot1 from './assets/products/lady23-01.jpg';
import boot2 from './assets/products/lady24-01.jpg';
import boot3 from './assets/products/lady25-01.jpg';
import boot4 from './assets/products/lady2-01.jpg';
import shoes1 from './assets/products/lady5-01.jpg';
import shoes2 from './assets/products/lady9-01.jpg';
import shoes3 from './assets/products/lady1-01.jpg';
import shoes4 from './assets/products/lady 7-01.jpg';
import shoes5 from './assets/products/lady22-01.jpg';
import shoes6 from './assets/products/lady16-01.jpg';
import shoes7 from './assets/products/lady21-01.jpg';
import shoes8 from './assets/products/lady18-01.jpg';
import shoes9 from './assets/products/lady17-01.jpg';
import shoes10 from './assets/products/shoes8-01.png';
import loafers1 from './assets/products/lady14-01.jpg';
import loafers2 from './assets/products/lady15-01.jpg';
import loafers3 from './assets/products/lady16-01.jpg';
import loafers4 from './assets/products/shoes8-01.png';

const Women = () => {
  const [selectedFilter, setSelectedFilter] = useState('All');

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  // Sample product data
  const products = [
    // Sneakers
    { id: 1, category: 'Sneakers', name: 'Skyblue', price: 27500, image: sneaker1 },
    { id: 2, category: 'Sneakers', name: 'LEO Black', price: 60000, image: sneaker2 },
    { id: 3, category: 'Sneakers', name: 'Naval Blue', price: 45000, image: sneaker3 },
    { id: 4, category: 'Sneakers', name: 'Drab Brown', price: 50000, image: sneaker4 },
    { id: 5, category: 'Sneakers', name: 'Oxford Brown', price: 35000, image: sneaker5 },
    { id: 6, category: 'Sneakers', name: 'Nubuck Walk', price: 38000, image: sneaker6 },

    // Boots
    { id: 7, category: 'Boots', name: 'Versant', price: 45000, image: boot1 },
    { id: 8, category: 'Boots', name: 'Equestrian', price: 80000, image: boot2 },
    { id: 9, category: 'Boots', name: 'Navy Flannel', price: 55000, image: boot3 },
    { id: 10, category: 'Boots', name: 'Rodeo Boot', price: 63000, image: boot4 },

    // Shoes
    { id: 11, category: 'Shoes', name: 'Shoes 1', price: 47000, image: shoes1 },
    { id: 12, category: 'Shoes', name: 'Shoes 2', price: 62500, image: shoes2 },
    { id: 13, category: 'Shoes', name: 'Shoes 3', price: 38000, image: shoes3 },
    { id: 14, category: 'Shoes', name: 'Shoes 4', price: 42000, image: shoes4 },
    { id: 15, category: 'Shoes', name: 'Shoes 5', price: 37000, image: shoes5 },
    { id: 16, category: 'Shoes', name: 'Shoes 6', price: 37000, image: shoes6 },
    { id: 17, category: 'Shoes', name: 'Shoes 7', price: 37000, image: shoes7 },
    { id: 18, category: 'Shoes', name: 'Shoes 8', price: 37000, image: shoes8 },
    { id: 19, category: 'Shoes', name: 'Shoes 9', price: 37000, image: shoes9 },
    { id: 20, category: 'Shoes', name: 'Shoes 10', price: 37000, image: shoes10 },

    // Loafers
    { id: 21, category: 'Loafers', name: 'Loafers 1', price: 38000, image: loafers1 },
    { id: 22, category: 'Loafers', name: 'Loafers 2', price: 42000, image: loafers2 },
    { id: 23, category: 'Loafers', name: 'Loafers 3', price: 37000, image: loafers3 },
    { id: 24, category: 'Loafers', name: 'Loafers 4', price: 56000, image: loafers4 },

    // Add more product items
  ];
  // Apply the selected filter to filter the products
  const filteredProducts = selectedFilter === 'All' ? products : products.filter(product => product.category === selectedFilter);

  return (
    <>
      <section className="men-section">
        <div className="sidebar-panel">
          <ul>
            <li
              className={selectedFilter === 'All' ? 'active' : ''}
              onClick={() => handleFilterClick('All')}
            >
              All Shoes
            </li>
            <li
              className={selectedFilter === 'Sneakers' ? 'active' : ''}
              onClick={() => handleFilterClick('Sneakers')}
            >
              SNEAKERS
            </li>
            <li
              className={selectedFilter === 'Boots' ? 'active' : ''}
              onClick={() => handleFilterClick('Boots')}
            >
              SLIPPERS
            </li>
            <li
              className={selectedFilter === 'Shoes' ? 'active' : ''}
              onClick={() => handleFilterClick('Shoes')}
            >
              SHOES
            </li>
            <li
              className={selectedFilter === 'Loafers' ? 'active' : ''}
              onClick={() => handleFilterClick('Loafers')}
            >
              LOAFERS
            </li>
            {/* Add other filter categories */}
          </ul>
        </div>
        <div className="product-list">
          {filteredProducts.map((product) => (
            <div className="product-item" key={product.id}>
              <img src={product.image} alt={product.name} /> {/* Display the product image */}
              <h3>{product.name}</h3>
              <p>${product.price}</p>
              {/* Clickable link to cart (You can replace the '#' with your actual cart route) */}
              {/* <a href="#" className="add-to-cart-link">
                Add to Cart
              </a> */}
            </div>
          ))}
        </div>
      </section>

      <Footer />
      <Bottomft />
    </>
  );
};


 
export default Women;

 