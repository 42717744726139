import React from 'react'
import './Category.css';

const Category = () => {
  return (
    <div className='categories'>

    <div className='boxes1'>
    <div class="text-container">

    <h1 className='cat-text'>
        <a href='#' className='link-color'>MEN</a>    
    </h1>
</div>

    </div>

    <div className='boxes2'>
      <div class="text-container">
    <h1 className='cat-text'>
    <a href='#' className='link-color'>WOMEN</a> 
    </h1>
</div>
        </div>

        
      
    </div>
  )
}

export default Category
