import React, { useState, useEffect } from "react";
import "./Slider.css";
import Slider1 from './assets/thebg1.jpeg';
import Slider2 from './assets/w1.jpeg';
import Slider3 from './assets/cy1.jpeg';
import Slider4 from './assets/sandal1.jpeg';
import Slider5 from './assets/chelseawomen.jpeg';

const Slideshow = () => {
  const [index, setIndex] = useState(0);
  const images = [
    {
      src: Slider1,
      description: "A lasting impression",
      subtext: "Exude timeless elegance and impeccable style for any formal occasion.",
      buttonText: "Shop Now",
      buttonLink: "#",
    },
    {
      src: Slider2,
      description: "polished aesthetic",
      subtext: "Achieve summer sophistication with these refined suede shoes.",
      buttonText: "Shop Now",
      buttonLink: "#",
    },
    {
      src: Slider3,
      description: "Exude timeless elegance",
      subtext: "Embrace the modern gentleman's style with these suede chelsea boots",
      buttonText: "Shop Now",
      buttonLink: "#",
    },
    {
      src: Slider4,
      description: "The urban explorer",
      subtext: "Discover the perfect blend of style and comfort with these contemporary sandals.",
      buttonText: "Shop Now",
      buttonLink: "#",
    },
    {
      src: Slider5,
      description: "ultimate comfort",
      subtext: "Designed for long journeys or a casual day out, these shoes provide unmatched comfort and a touch of sophistication",
      buttonText: "Shop Now",
      buttonLink: "#",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Change slide every 6 seconds

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const handleSlideChange = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="slideshow">
      {images.map((image, i) => (
        <div
          key={i}
          className={`slide ${i === index ? "active" : ""}`}
          style={{ backgroundImage: `url(${image.src})` }}
        >
          <div className="overlay">
            <div className="slideshow-text">
              <h1>{image.description}</h1>
              <p>{image.subtext}</p>
              <a href={image.buttonLink} className="shop-button">{image.buttonText}</a>
            </div>
          </div>
        </div>
      ))}
      <div className="pagination">
        {images.map((_, i) => (
          <button
            key={i}
            className={`pagination-dot ${i === index ? "active" : ""}`}
            onClick={() => handleSlideChange(i)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
