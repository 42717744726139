import React from 'react';
import './Popup.css'; // Make sure to import the CSS file for the popup styles
import fall from './assets/fall_discount_image.jpg';

const Popup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <img src={fall} alt="Logo" style={{ width: '400px' }} />
        <p className="discount-message">
          FALL discount on all products. 30% off. Use code AFRK0823.
        </p>
      </div>
    </div>
  );
};

export default Popup;
