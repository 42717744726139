import React, { useState } from 'react';
import firebase from './firebaseConfig';
import './Signup.css';

const Signup = () => {
  const [fullName, setFullName] = useState('');
  const [location, setLocation] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const countries = [
    { label: 'Afghanistan', value: 'AF' },
    // ... other country options
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;

      if (user) {
        alert('Account Created successfully');
        window.location.href = '/login'; // Redirect upon successful signup
      }
    } catch (error) {
      console.error('Error signing up:', error.message);
      // Handle signup errors here, update state or display error messages
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-form">
      <h1 className="signup-form-heading">Sign Up</h1>
      <form onSubmit={handleSubmit} className="signup-form-form">
        <div className="signup-form-item">
          <input
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="signup-form-input"
            required
          />
        </div>

        <div className="signup-form-item">
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="signup-form-select"
            required
          >
            <option value="">Select your country</option>
            {countries.map((country) => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </div>

        <div className="signup-form-item">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-form-input-email"
            required
          />
        </div>

        <div className="signup-form-item">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-form-input"
            required
            minLength={6}
          />
        </div>

        <div className="signup-form-item">
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="signup-form-input"
            required
            minLength={6}
          />
        </div>

        <div className="signup-form-item">
          <button type="submit" disabled={loading} className="signup-form-button">
            {loading ? 'Signing Up...' : 'Sign Up'}
          </button>
        </div>
      </form>

      <p>
        Do you have an account? <a href="/login">Login</a>
      </p>
    </div>
  );
};

export default Signup;
