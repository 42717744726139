import React from 'react';
import './Sneakers.css';  
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';



import sneaker1 from './assets/products/product2.png';
import sneaker2 from './assets/products/product1.png';
import sneaker3 from './assets/products/sneaker.jpeg';
import sneaker4 from './assets/products/products5.png';
import sneaker5 from './assets/products/brown.png';
import sneaker6 from './assets/products/nubuck.png';

const Sneakers = () => {
  const sneakersData = [
    {
      id: 1,
      name: 'Double-Buckle',
      image: sneaker1,
      price: '₦35000',
    },
    {
      id: 2,
      name: 'LEO Black',
      image: sneaker2,
      price: '₦60000',
    },
    {
      id: 3,
      name: 'Naval Blue',
      image: sneaker3,
      price: '₦45000',
    },
    {
      id: 4,
      name: 'Drab Brown',
      image: sneaker4,
      price: '₦43000',
    },
    {
      id: 5,
      name: 'Oxford Brown',
      image: sneaker5,
      price: '₦38000',
    },
    {
      id: 6,
      name: 'Nubuck Walk',
      image: sneaker6,
      price: '₦42000',
    },
  ];

  return (
    <>
    <div className="sneakers-container">
      {sneakersData.map(sneaker => (
        <div key={sneaker.id} className="sneaker-card">
          <img src={sneaker.image} alt={sneaker.name} />
          <h3>{sneaker.name}</h3>
          <p>{sneaker.price}</p>
        </div>
      ))}
    </div>    <Footer />
      <Bottomft />

      </>
  );
};

export default Sneakers;
