import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './OrderConfirm.css';


const OrderConfirm = () => {
  const location = useLocation();
  const { cartItems = [], shippingInfo = {}, totalAmount = 0, reference = '' } = location.state || {};

  return (
  
      <div className="order-confirmation">
        <div className="order-text">
          <h2 className="thanks-title">Order Placed Successfully</h2>
          <h4 className="order-subtitle">Your order is being processed. You will receive an order confirmation email shortly.</h4>
        </div>
        <p className="calculated">Order Total: ₦{totalAmount.toLocaleString()}</p>

        <div className="order-info">
          <div className="info-section">
            <h3>Your Information</h3>
            <p>{shippingInfo.fullName || 'N/A'}</p>
            <p>{shippingInfo.email || 'N/A'}</p>
            <Link to="/account">Edit</Link>
          </div>

          <div className="info-section">
            <h3>Shipping Address</h3>
            <p>{shippingInfo.streetAddress || 'N/A'}</p>
            <p>{`${shippingInfo.city || 'N/A'}, ${shippingInfo.state || 'N/A'}`}</p>
            <p>{shippingInfo.countryRegion || 'N/A'}</p>
            <p>{shippingInfo.phone || 'N/A'}</p>
            <Link to="/account">Edit</Link>
          </div>

          <div className="info-section">
            <h3>Billing Address</h3>
            <p>{shippingInfo.streetAddress || 'N/A'}</p>
            <p>{`${shippingInfo.city || 'N/A'}, ${shippingInfo.state || 'N/A'}`}</p>
            <p>{shippingInfo.countryRegion || 'N/A'}</p>
            <Link to="/account">Edit</Link>
          </div>
        </div>

        <div className="order-details">
          <h3 className="order-det">Order Details</h3>
          <ul>
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <li key={item.id}>
                  <div className="item-summary">
                    <img src={item.image} alt={item.name} className="item-image" />
                    <div className="item-details">
                      <p className="item-name">{item.name}</p>
                      <p className="item-quantity">Quantity: {item.quantity}</p>
                      <p className="item-price">₦{(item.price * item.quantity).toLocaleString()}</p>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p>No items in the order.</p>
            )}
          </ul>
        </div>

        <p className="reference">
          Payment Reference: {reference || 'N/A'}
        </p>
      </div>
    
  );
};

export default OrderConfirm;
