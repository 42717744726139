import React, { useState } from 'react';
import Slider from '../components/Slider';
import Category from '../components/Category';
import Products from '../components/Products';
import Ladies from '../components/Ladies';
import Promo from '../components/Promo';
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';
import Popup from './Popup'; // Import the Popup component

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {/* Conditionally render the pop-up based on the `showPopup` state */}

      {/* Other components */}
      <Slider />
      <Category />
      <Products />
      <Ladies />
      <Promo />
      <Footer />
      <Bottomft />
      {showPopup && <Popup onClose={handleClosePopup} />}

    </>
  );
};

export default Home;
