import React from 'react'

const Request = () => {
  return (
    <div>
      <h2>Request Shoe</h2>
    </div>
  )
}

export default Request
