import React from 'react';
import { Link } from 'react-router-dom';
import './Signin.css';
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';


const Signin = () => {
  return (
    <>
    <div className="signin-page">
      <h2>Sign In</h2>
      <p>If you have an account, sign in to continue. Otherwise, proceed as a guest.</p>
      
      {/* Sign in button */}
      <Link to="/login" className="login">
        <button className="signin-btn">Sign In</button>
      </Link>

      {/* Or proceed as a guest */}
      <p className="guest-proceed">
        Proceed to <Link to="/checkout">Place Order</Link> as a guest
      </p>
      
    </div>

<Footer />
<Bottomft />
         </>
  );
};

export default Signin;
