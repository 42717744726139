// Cart.js
import React from 'react';
import { useCart } from './CartContext';

const Cart = () => {
  const { cartItems } = useCart();

  return (
    <div className='cart'>
      <h2>Your Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <ul>
          {cartItems.map((item, index) => (
            <li key={index}>
              <img src={item.image} alt={item.name} />
              <div>{item.name}</div>
              <div>Size: {item.size}</div>
              <div>Color: {item.color}</div>
              <div>Quantity: {item.quantity}</div>
              <div>Price: {item.price}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Cart;
