import React, { useState } from 'react';
import { useCart } from './CartContext';
import { RiDeleteBinLine } from 'react-icons/ri';
import './Checkout.css';
import { Link } from 'react-router-dom';

const Checkout = () => {
  const { cartItems, cartItemsCount, removeFromCart } = useCart();
  const [shippingCost] = useState(5000);

  const handleCheckout = async () => {
    // Calculate the total cost including shipping
    const totalCost =
      cartItems.reduce((total, item) => total + item.price * item.quantity, 0) + shippingCost;

    // Implement your Paystack payment logic here
    // For this example, we'll simulate a successful payment after 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Optionally clear the cart after successful checkout
    // clearCart();
  };

  const calculateTotalCost = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  return (
    <div className="checkout-page">
      <h2>Checkout</h2>
      <p className="proceed">
        If this item has been added to your cart, you can proceed to checkout.
      </p>
      <hr className="strike" />
      
      {cartItemsCount === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div className="checkout-items">
          {cartItems.map((item) => (
            <div key={item.id} className="checkout-item">
              <img src={item.image} alt={item.name} />
              <div className="item-details">
                <p>{item.name}</p>
                <p>Quantity: {item.quantity}</p>
                <p>Price: ₦{item.price}</p>
                <p>Total: ₦{item.price * item.quantity}</p>
              </div>
              <div
                className="delete-icon"
                onClick={() => removeFromCart(item.id)}
                role="button"
                aria-label="Remove item"
              >
                <RiDeleteBinLine />
              </div>
            </div>
          ))}
          <div className="shipping-details">
            <p>Shipping within Lagos: ₦{shippingCost}</p>
            <p>Total Items: {cartItemsCount}</p>
            <p>Total Cost: ₦{calculateTotalCost()}</p>
            <p>Total Cost with Shipping: ₦{calculateTotalCost() + shippingCost}</p>
          </div>
          <button onClick={handleCheckout} className="btn_chq">
            <Link to="/signin" className="checkout-link">
              Proceed to Checkout
            </Link>
          </button>
        </div>
      )}
    </div>
  );
};

export default Checkout;
