import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import './Topbar.css';

const TopBar = () => {
  const promoData = [
    {
      text: 'DISCOVER AFR91 CAMPAIGN',
      link: '/new',
    },
    {
      text: 'SHOP BY LOOK AFR91',
      link: '/new',
    },
    {
      text: 'SIGNUP FOR A PRIVATE SESSION',
      link: '/new',
    },
  ];

  const settings = {
    showArrows: true,
    enableAutoPlay: true,
    autoPlaySpeed: 3000, // Set the interval for automatic sliding (in milliseconds)
    infinite: true, // Set infinite to true to enable infinite looping
  };

  return (
    <div className="topbar">
      <Carousel {...settings} className="promo-slider">
        {promoData.map((promo, index) => (
          <Link to={promo.link} key={index} className="promo-link">
            {promo.text}
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default TopBar;
