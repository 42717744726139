import React, { useState } from 'react';
import './Men.css';
import ProductModal from './ProductModal'; // Update the path
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';
import sneaker1 from './assets/products/product2.png';
import sneaker2 from './assets/products/product1.png';
import sneaker3 from './assets/products/sneaker.jpeg';
import sneaker4 from './assets/products/shoedoublebuck.png';
import sneaker5 from './assets/products/brown.png';
import sneaker6 from './assets/products/nubuck.png';
import boot1 from './assets/products/boot1-01.png';
import boot2 from './assets/products/boot2-01.png';
import boot3 from './assets/products/boot3-01.png';
import boot4 from './assets/products/boot4-01.png';
import shoes1 from './assets/products/shoe2.png';
import shoes2 from './assets/products/shoe3.png';
import shoes3 from './assets/products/shoes4-01.png';
import shoes4 from './assets/products/shoes7-01.png';
import shoes5 from './assets/products/shoes9-01.png';
import shoes6 from './assets/products/shoes8-01.png';
import shoes7 from './assets/products/shoes4-01.png';
import shoes8 from './assets/products/shoes7-01.png';
import shoes9 from './assets/products/shoes9-01.png';
import shoes10 from './assets/products/shoes8-01.png';
import loafers1 from './assets/products/shoes4-01.png';
import loafers2 from './assets/products/shoes7-01.png';
import loafers3 from './assets/products/shoes9-01.png';
import loafers4 from './assets/products/shoes8-01.png';

const Men = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [cart, setCart] = useState([]); // Initialize cart state

  const [selectedFilter, setSelectedFilter] = useState('All');

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  // Sample product data
  const products = [
    // Sneakers
    { id: 1, category: 'Sneakers', name: 'Double-Buckle', price: 35000, image: sneaker1 },
    { id: 2, category: 'Sneakers', name: 'LEO Black', price: 60000, image: sneaker2 },
    { id: 3, category: 'Sneakers', name: 'Naval Blue', price: 45000, image: sneaker3 },
    { id: 4, category: 'Sneakers', name: 'Drab Brown', price: 50000, image: sneaker4 },
    { id: 5, category: 'Sneakers', name: 'Oxford Brown', price: 35000, image: sneaker5 },
    { id: 6, category: 'Sneakers', name: 'Nubuck Walk', price: 38000, image: sneaker6 },

    // Boots
    { id: 7, category: 'Boots', name: 'Versant', price: 45000, image: boot1 },
    { id: 8, category: 'Boots', name: 'Equestrian', price: 80000, image: boot2 },
    { id: 9, category: 'Boots', name: 'Navy Flannel', price: 55000, image: boot3 },
    { id: 10, category: 'Boots', name: 'Rodeo Boot', price: 63000, image: boot4 },

    // Shoes
    { id: 11, category: 'Shoes', name: 'Shoes 1', price: 47000, image: shoes1 },
    { id: 12, category: 'Shoes', name: 'Shoes 2', price: 62500, image: shoes2 },
    { id: 13, category: 'Shoes', name: 'Shoes 3', price: 38000, image: shoes3 },
    { id: 14, category: 'Shoes', name: 'Shoes 4', price: 42000, image: shoes4 },
    { id: 15, category: 'Shoes', name: 'Shoes 5', price: 37000, image: shoes5 },
    { id: 16, category: 'Shoes', name: 'Shoes 6', price: 37000, image: shoes6 },
    { id: 17, category: 'Shoes', name: 'Shoes 7', price: 37000, image: shoes7 },
    { id: 18, category: 'Shoes', name: 'Shoes 8', price: 37000, image: shoes8 },
    { id: 19, category: 'Shoes', name: 'Shoes 9', price: 37000, image: shoes9 },
    { id: 20, category: 'Shoes', name: 'Shoes 10', price: 37000, image: shoes10 },

    // Loafers
    { id: 21, category: 'Loafers', name: 'Loafers 1', price: 38000, image: loafers1 },
    { id: 22, category: 'Loafers', name: 'Loafers 2', price: 42000, image: loafers2 },
    { id: 23, category: 'Loafers', name: 'Loafers 3', price: 37000, image: loafers3 },
    { id: 24, category: 'Loafers', name: 'Loafers 4', price: 56000, image: loafers4 },

    // Add more product items
  ];
  // Apply the selected filter to filter the products
  const filteredProducts = selectedFilter === 'All' ? products : products.filter(product => product.category === selectedFilter);

  return (
    <>
      <section className="men-section">
        <div className="sidebar-panel">
          <ul>
            <li
              className={selectedFilter === 'All' ? 'active' : ''}
              onClick={() => handleFilterClick('All')}
            >
              All Shoes
            </li>
            <li
              className={selectedFilter === 'Sneakers' ? 'active' : ''}
              onClick={() => handleFilterClick('Sneakers')}
            >
              SNEAKERS
            </li>
            <li
              className={selectedFilter === 'Boots' ? 'active' : ''}
              onClick={() => handleFilterClick('Boots')}
            >
              BOOTS
            </li>
            <li
              className={selectedFilter === 'Shoes' ? 'active' : ''}
              onClick={() => handleFilterClick('Shoes')}
            >
              SHOES
            </li>
            <li
              className={selectedFilter === 'Loafers' ? 'active' : ''}
              onClick={() => handleFilterClick('Loafers')}
            >
              LOAFERS
            </li>
            {/* Add other filter categories */}
          </ul>
        </div>
        <div className="product-list">
          {filteredProducts.map((product) => (
            <div className="product-item" key={product.id}>
              <img src={product.image} alt={product.name} /> {/* Display the product image */}
              <h3>{product.name}</h3> {/* Display the product title */}
              <p>₦{product.price}</p> {/* Display the product price */}
              <button onClick={() => setSelectedProduct(product)}  className='menbtn'>View Details</button>
            </div>
          ))}
        </div>
      </section>

      <ProductModal
        product={selectedProduct}
        isOpen={selectedProduct !== null}
        onClose={() => setSelectedProduct(null)}
        onAddToCart={() => {
          const newItem = {
            product: selectedProduct,
            size: selectedSize,
            color: selectedColor,
            quantity: selectedQuantity,
          };
          setCart([...cart, newItem]);
          setSelectedProduct(null);
          setSelectedSize('');
          setSelectedColor('');
          setSelectedQuantity(1);
        }}
      />

      <Footer />
      <Bottomft />
    </>
  );
};

export default Men;
