import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import './ProductsSection.css';

import productsOne from './assets/products/product1.png';
import productsTwo from './assets/products/product2.png';
import productsThree from './assets/products/product3.png';
import productsFour from './assets/products/products4.png';
import productsFive from './assets/products/products5.png';
import productsSix from './assets/products/product6.png';


const ProductsSection = () => {
  const productsData = [
    { title: "Men's White Sole Sneakers", price: '55,000.00', image: productsOne },
    { title: "Men's brown leather double-buckle", price: '65,000.00', image: productsTwo },
    { title: "Men's dab-brown double-buckle", price: '59,500.00', image: productsThree },
    { title: "Men's plain leather shoe black", price: '37,000.00', image: productsFour },
    { title: "Men's brown leather tassel sneakers", price: '65,000.00', image: productsFive },
    { title: "Brown Leather Chelsea Boot White Sole", price: '12.99', image: productsSix },
  ];

  const [itemsToShow, setItemsToShow] = useState(4);

  const updateItemsToShow = () => {
    const screenWidth = window.innerWidth;
    setItemsToShow(screenWidth >= 768 ? 4 : 1);
  };

  useEffect(() => {
    updateItemsToShow();
    window.addEventListener('resize', updateItemsToShow);
    return () => {
      window.removeEventListener('resize', updateItemsToShow);
    };
  }, []);

  const settings = {
    showArrows: true,
    pagination: false,
    itemsToShow: itemsToShow,
  };

  return (
    <div className="products-section">
      <Carousel {...settings} className="product-slider">
        {productsData.map((product, index) => (
          <Link to={`/productdetails/${index + 1}`} key={index} className="product-link">
            <img src={product.image} alt={product.title} style={{ width: '270px', height: '180px' }} />
            <h3 className="product-title">{product.title}</h3>
            <p className="product-price">₦{product.price}</p>
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductsSection;